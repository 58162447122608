import React from "react"
import Layout from "../layout"
import { Link } from "gatsby"

const Utbildningar = () => {
  return (
    <Layout>
      <div
        style={{
          padding: "3em",
          backgroundColor: "#FAFAFA",
          color: "333",
          maxWidth: "50em",
        }}
      >
        <h1>Utbildningsinfo</h1>
        <p>Vi erbjuder följande utbildningar</p>
        <h2>Bil</h2>
        <p>
          Vi erbjuder körlektioner med både manuell och automatbil. Dessa
          lektioner är 40 minuter långa, men vi kör alltid 80 minuters
          dubbellektioner och vi hoppas du ska få en trivsam och effektiv
          utbildningstid. Vårt mål är att du ska bli en säker förare.
        </p>
        <h2>MC</h2>
        <p>
          Vi erbjuder körlektioner för A1 (lätt) och A(tung). Dessa lektioner är
          55 minuter och bokas ofta som dubbellektioner så det blir 110 minuter
          långa MC körlektioner. Vi kör Kawasaki Z650 2020/21
        </p>
        <h2>Moped</h2>
        <p>
          Vi erbjuder även utbildning för moped (AM).{" "}
          <Link
            to={"/priser"}
            state={{
              page: "MOPED",
            }}
          >
            Läs mer och boka på moped-sidan
          </Link>
        </p>
        <h2>Handledarkurs</h2>
        <p>
          Är en obligatorisk kurs för dig som ska köra privat. Både handledaren
          och eleven måste gå kursen. Kursen är 3 timmar och 20 minuter lång och
          då ingår även fika. Välkommen att boka via startsidan.{" "}
          <a href="https://www.transportstyrelsen.se/sv/vagtrafik/Korkort/handledare/introduktionsutbildning/">
            Läs mer om introdukutionsutbildningen hos Transportstyrelsen
          </a>
        </p>
        <h2>Teorikurs</h2>
        <p>
          Vår teoriutbildningars sker online via elevcentralen. Samtidigt som du
          läser böcker som du får av oss om du köper ett paket. För övrigt går
          såklart att köpa onlilnetester samt bok-paket separat.
        </p>
        <h2>Risk 1:an</h2>
        <p>
          Är en obligatorisk utbildning när man ska ta ett körkort. Utbildningen
          lyfter fram attityder som är riskfyllda i trafiken bl.a. beteende,
          alkohol, droger och trötthet. Utbildningen är 3 timmar och 20 minuter,
          där ingår även fika.
        </p>
        <h2>Risk 2:an</h2>
        <p>
          Halkbanan, eller Risktvåan som utbildningen egentligen heter, är ett
          obligatoriskt moment som du måste klara för att ta körkort. Syftet med
          att göra Halkan är för att du ska lära dig mer om hur du bäst hanterar
          allvarliga och farliga situationer, när du tappar kontrollen över
          bilen. Vår rekommendation är att man skall ha kommit rätt långt i
          körutbildningen och förstå risker samt köra i inhägnat område för att
          boka risktvåan.
{/*          <a href="https://www.storaholm.se/riskutbildning-for-bil-risk-2/?gclid=Cj0KCQjwkIGKBhCxARIsAINMioJGMOz8QGw6mR0fWMJTcaXpG7Itvc0wkKCFtss_TJj8HHUZYaS00rYaAh0HEALw_wcB">
            Mer info och bokning hos Stora Holm
          </a>*/}
        </p>
        <h2>Teorihjälp</h2>
        <p>
          Har du problem med teorin? Boka in en privat teorilektion, där du är
          ensam med en trafiklärare. För tillfället kostar en timmes teorihjälp
          600kr, du är välkommen att{" "}
          <Link to={"/priser"}>beställa via pris-sidan!</Link>
        </p>
        <h2>Intensivutbildning</h2>
        <p>
          Vi erbjuder intensivutbildning i din egen takt. Intensivutbildningen
          är 20 körlektioner (bil) & 10 körlektioner (MC), teorikurs,
          webbtester, Risk1:an och alla böcker som du behöver. Alla våra paket
          skall utnyttjas inom 6 månader.
        </p>
      </div>
    </Layout>
  )
}

export default Utbildningar
